$primary: #6f42c1;

@import 'bootstrap/scss/bootstrap';


mark {
  background-color: $primary !important;
  color: #fff !important;
  font-weight: bolder;
  padding: 3px;
}

span.noselect {
  opacity: 0;
  color: white;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}